import React, { useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './assets/scss/inputField.scss';
import './assets/scss/main.scss';
import Header from './components/Header';
// import FormContainer from './containers/FormContainer';
import ControlPanel from './components/sections/ControlPanel'; // Import your ControlPanel component
import UserProfile from './components/sections/UserProfile';
import ResetPassword from './components/sections/resetPassword';
import ChangeOrder from './containers/ChangeOrder';
import CheckList from './containers/CheckList';
import FormContainerWithAuth from './containers/FormContainer';
import LoginPrompt from './containers/LoginPrompt';
import OrderRoutines from './containers/OrderRoutines';

import UserContext from './services/UserContext';

const initialUser = {
	isLoggedIn: !!localStorage.getItem('token'),
	role: localStorage.getItem('userRole') || null,
	first_name: localStorage.getItem('first_name') || null,
	last_name: localStorage.getItem('last_name') || null,
	email: localStorage.getItem('email') || null,
};

function App() {
	const [user, setUser] = useState(initialUser);
	const [totalPrice, setTotalPrice] = useState(0);

	const handlePriceChange = (e) => {
		const price = parseFloat(e.target.getAttribute('data-price'));
		if (e.target.checked) {
			setTotalPrice((prevTotal) => prevTotal + price);
		} else {
			setTotalPrice((prevTotal) => prevTotal - price);
		}
	};

	return (
		<UserContext.Provider value={{ user, setUser }}>
			<Router>
				<div className='App'>
					<header className='App-header'>
						<Header totalPrice={totalPrice} />
					</header>
					<section>
						<Routes>
							<Route
								path='/'
								element={
									<FormContainerWithAuth
										handlePriceChange={handlePriceChange}
									/>
								}
							/>
							<Route
								path='/login-prompt'
								element={<LoginPrompt />}
							/>
							<Route
								path='/reset-password/:token'
								element={<ResetPassword />}
							/>
							<Route
								path='/reset-password/'
								element={<ResetPassword />}
							/>
							<Route
								path='/control-panel/*'
								element={<ControlPanel />}
							/>
							<Route path='/profile' element={<UserProfile />} />
							<Route
								path='/change-order'
								element={<ChangeOrder />}
							/>
							<Route path='/checklist' element={<CheckList />} />
							<Route
								path='/order-routines'
								element={<OrderRoutines />}
							/>
						</Routes>
					</section>
				</div>
			</Router>
		</UserContext.Provider>
	);
}

export default App;
